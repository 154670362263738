<template>
  <div class="txrecord">
    <title-top>余额明细</title-top>

    <div class="h20"></div>
    <div
      class="row align-center between bg-white h146 mb20"
      v-for="(item,index) in list"
      :key="index"
    >
      <div class="column ml25">
        <span class="font28 f-1A1A1A ellipsis">{{item.detail}}</span>
        <span class="font24 f-666666">{{item.created_at | formatDate}}</span>
      </div>
      <span class="font24 f-F62341 ml16 mr25">{{item.amount}}</span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";
import { formatDate } from "common/util";
import { account_logs } from "network/igou";
export default {
  name: "YeDetail",
  data() {
    return {
      list: []
    };
  },
  mounted() {
    account_logs().then(res => {
      console.log(res);
      this.list = res.data;
    });
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    formatHs(time) {
      var date = new Date(time);
      return formatDate(date, "hh:mm");
    },
    formatYear(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    }
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
</style>
